
import { Component, Vue } from "vue-property-decorator";
import PageTitleBar from "@/components/PageTitleBar.vue";
import AttachmentIcon from "@/components/AttachmentIcon.vue";
import PostDetailsPagination from "@/components/PostDetailsPagination.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";
import { InvestmentPhilosophy } from "../interfaces/BrainLibrary.dto";

import { formatIOSDateToMMDDYYYY } from "../utils/Functions";

@Component({
  components: {
    PageTitleBar,
    AttachmentIcon,
    PostDetailsPagination,
    BreadCrumbs,
  },
})
export default class InvestmentsPhilosophy extends Vue {
  title = "Investment Philosophy";

  breadCrumbItems: Array<BreadCrumbDTO> = [];

  brainLibraryPhilosophy: InvestmentPhilosophy = {
    _id: "",
    description: "",
    investmentPhilosophyAttachments: [],
    createdAt: "",
  };

  mounted(): void {
    this.createBreadCrumbItem();
    this.refresh();
  }

  async refresh(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/investment-philosophy");
      if (!data) return;

      this.brainLibraryPhilosophy = data;
      this.brainLibraryPhilosophy.createdAt =
        this.brainLibraryPhilosophy.createdAt.substr(0, 16);
    } catch (error) {
      console.error(error);
    }
  }

  createBreadCrumbItem(): void {
    this.breadCrumbItems = [
      {
        text: this.title,
        goToPath: "",
        clickable: false,
      },
    ];
  }

  formatDate(date: string): string {
    return formatIOSDateToMMDDYYYY(date || "");
  }
}
